import "./Assets/scss/style.scss";
import React, { Suspense, lazy, useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  Navigate,
} from "react-router-dom";
import Load from "./Components/Load";
import { useLoginStore } from "./Views/Auth/store";
import DetailPengguna from "./Views/Pages/Pengguna/DetailPengguna";
const Login = lazy(() => import("./Views/Auth/Login"));
const Index = lazy(() => import("./Views/Pages/Index"));
const Dashboard = lazy(() => import("./Views/Pages/Dashboard/Dashboard"));
const HasilPemilu = lazy(() => import("./Views/Pages/HasilPemilu/HasilPemilu"));
const Pengguna = lazy(() => import("./Views/Pages/Pengguna/Pengguna"));
const TambahPengguna = lazy(() => import("./Views/Pages/Pengguna/AddPengguna"));
const EditPengguna = lazy(() => import("./Views/Pages/Pengguna/EditPengguna"));
const Pemilih = lazy(() => import("./Views/Pages/Pemilih/Pemilih"));
const EditPemilih = lazy(() => import("./Views/Pages/Pemilih/EditPemilih"));
const ImportPemilih = lazy(() => import("./Views/Pages/Pemilih/ImportPemilih"));
const ImportKategori = lazy(() =>
  import("./Views/Pages/Pemilih/ImportKategori")
);

const RealcountImport = lazy(() =>
  import("./Views/Pages/RealcountImport/RealcountImport")
);
const ImportRc = lazy(() => import("./Views/Pages/RealcountImport/ImportRc"));

const DetailPemilih = lazy(() => import("./Views/Pages/Pemilih/DetailPemilih"));
const Laporan = lazy(() => import("./Views/Pages/Laporan/Laporan"));
const Kontak = lazy(() => import("./Views/Pages/Kontak/Kontak"));
const Target = lazy(() => import("./Views/Pages/Target/Target"));
const AddTarget = lazy(() => import("./Views/Pages/Target/AddTarget"));
const EditTarget = lazy(() => import("./Views/Pages/Target/EditTarget"));

const pages = [
  { el: <RealcountImport />, url: "rc-import" },
  { el: <Pemilih />, url: "data-anggota" },
  { el: <HasilPemilu />, url: "hasil-pemilu" },
  {
    el: <RoleComp el={<Target />} role={["superadmin"]} />,
    url: "data-target",
  },
  {
    el: <RoleComp el={<AddTarget />} role={["superadmin"]} />,
    url: "data-target/add",
  },
  {
    el: <RoleComp el={<EditTarget />} role={["superadmin"]} />,
    url: "data-target/edit/:id",
  },
  { el: <Kontak />, url: "kontak" },
  {
    el: <RoleComp el={<ImportPemilih />} role={["superadmin"]} />,
    url: "data-anggota/import-data",
  },
  {
    el: <RoleComp el={<ImportKategori />} role={["superadmin"]} />,
    url: "data-anggota/import-kategori",
  },
  {
    el: <RoleComp el={<EditPemilih />} role={["superadmin"]} />,
    url: "data-anggota/edit-pemilih/:id",
  },
  { el: <DetailPemilih />, url: "data-anggota/detail/:id" },
  { el: <Dashboard />, url: "dashboard" },
  { el: <RoleComp el={<Pengguna />} role={["superadmin"]} />, url: "pengguna" },
  { el: <RoleComp el={<Laporan />} role={["superadmin"]} />, url: "laporan" },
  {
    el: <RoleComp el={<TambahPengguna />} role={["superadmin"]} />,
    url: "pengguna/add",
  },
  {
    el: <RoleComp el={<EditPengguna />} role={["superadmin"]} />,
    url: "pengguna/edit/:id",
  },
  {
    el: <RoleComp el={<DetailPengguna />} role={["superadmin"]} />,
    url: "pengguna/detail/:id",
  },
  {
    el: <RoleComp el={<ImportRc />} role={["superadmin"]} />,
    url: "rc-import/import-data",
  },
];

function RequireAuth({ children }) {
  const [state, action] = useLoginStore();
  const location = useLocation();

  return state.isAuthed === true ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

function RoleComp({ el, role }) {
  const [state, action] = useLoginStore();

  return role.includes(state.profile.role) ? (
    el
  ) : (
    <Navigate to="/loged/dashboard" replace />
  );
}

function App() {
  const [state, action] = useLoginStore();

  useEffect(() => {
    action.checkUser();
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Load />}>
        <Routes>
          <Route
            path="/loged"
            element={
              <RequireAuth>
                <Index />
              </RequireAuth>
            }
          >
            {pages.map((page) => (
              <Route path={page.url} element={page.el} />
            ))}
          </Route>
          <Route path="/" element={<Login />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
